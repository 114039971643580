<template>
  <Page>
    <div class="home-page">
      <zui-header/>
      <div class="content-box">
        <div class="banner-box">
          <img class="welcome" src="https://www.achencybe.com/img/pc/order/1.png"/>
          <img :src="bannerImg" style="width: 1300px; margin-top: 40px"/>
        </div>
        <!--        v-if="type != 'carlistTHE5'"-->
        <template>
          <div class="shop-box">
            <div class="tips-text">
              {{ $t('order.main.title_1') }}
              <el-link
                  @click="
                $router.push({
                  name: 'FooterDetail',
                  query: { type: 'taobao' },
                })
              "
              >{{ $t('order.main.title_2') }}
              </el-link
              >
              {{ $t('order.main.title_3') }}
            </div>
            <div class="shop-list">
              <div v-for="item in shopList" :key="item.id">
                <div v-if="!item.hidden">
                  <div class="line"></div>
                  <div class="shop-item">
                    <img :src="item.img"/>
                    <div class="shop-info">
                      <h2 v-if="$i18n.locale === 'zh'">{{ $t(item.title) }}</h2>
                      <h2 v-else>{{ $t(item.title_en) }}</h2>
                      <span v-if="$i18n.locale === 'zh'">{{ $t(item.info) }}</span>
                      <span v-else>{{ $t(item.info_en) }}</span>
                      <ul>
                        <li v-for="li in item.list" :key="li" >
                          <template v-if="$i18n.locale==='zh'">
                            {{ $t(li.ch) }}
                          </template>
                          <template v-else>
                            {{ $t(li.en) }}
                          </template>
                        </li>
                      </ul>
                      <div class="attr-box" v-if="item.attrList && item.attrList.length <= 2">
                        <div v-for="(attr, idx) in item.attrList" :key="idx">
                          <h2 style=" white-space:nowrap" v-if="$i18n.locale === 'zh'">{{ $t(attr.type) }}</h2>
                          <h2 style=" white-space:nowrap" v-else>{{ $t(attr.type_en) }}</h2>
                          <div v-if="attr.subType && $i18n.locale === 'zh'" style="margin: 10px 0">
                            {{ attr.subType }}
                          </div>
                          <div v-if="attr.subTypeEn && $i18n.locale !== 'zh'" style="margin: 10px 0">
                            {{ attr.subTypeEn }}
                          </div>
                          <div v-if="attr.x3 && attr.title && $i18n.locale === 'zh'" style="margin: 10px 0">
                            {{ attr.title }}
                          </div>
                          <div v-if="attr.x3 && attr.title && $i18n.locale !== 'zh'" style="margin: 10px 0">
                            {{ attr.title_en }}
                          </div>
                          <button
                              :class="{ active:  attr.active }"
                              :style="{ paddingLeft: isPresell == '1' ? '40px' : '' }"
                              @click="hanldercheck(item, attr, idx)"
                              style="
                          font-family: 'ELEMENT-ICONS';
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                          >
                          <span v-if="isPresell === '1'" style="font-size: 22px; width: auto; font-weight: 500">
                            {{ $t('order.main.deposit') }}
                          </span>
                            <span v-else style="font-size: 22px; width: auto; font-weight: 500">

                          </span>
                            <span style="font-size: 22px; width: auto; font-weight: 500"
                                  v-if="language=== 'zh'">{{ formatPrice(attr.price) }}</span>
                            <span style="font-size: 22px; width: auto; font-weight: 500"
                                  v-else>{{ formatPrice(attr.price_dollar) }}</span>

                          </button>
                        </div>
                      </div>
                      <span style="color: #a9a9a9" v-if="language === 'zh'">
                    {{ $t('order.main.send.send_title') }} {{ clientAdders }}<br/>
                    {{ $t('order.main.send.send_estimation') }}{{ completionTime }} ({{ week }})
                    {{ $t('order.main.send.send_finish') }}
                  </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="buy-box">
            <div>{{ $t('order.main.footer') }}</div>
            <button @click="toGo">{{ $t('order.main.footer_button') }}</button>
          </div>
          <div style="display: none;" v-if="language = this.$i18n.locale === 'zh' ? 'zh': 'en' "></div>
        </template>
      </div>
      <zui-footer/>
    </div>
  </Page>
</template>
<script>
import "../../../less/home-page.less";
import Page from "../../../../components/Page";
import ZuiHeader from "../../../../components/Header";
import ZuiFooter from "../../../../components/Footer";

const cname = returnCitySN.cname;
export default {
  components: {Page, ZuiHeader, ZuiFooter},
  computed: {
    type() {
      return this.$route.query.cartype
          ? this.$route.query.cartype
          : "";
    },
    bannerImg() {
      if (this.type === "carlistX3") {
        return "https://www.achencybe.com/img/pc/x3/X3-9-1.png";
      } else {
        return "https://www.achencybe.com/img/pc/x3/X3-9-1.png";
      }
    },
  },
  watch: {
    type: {
      language(val) {
        this.language = val
      },
      handler(val) {
        let valTypeOption = {
          carlistX3: "THE X3",
        }
        let data = {carModelName: valTypeOption[val]};
        if (val === "carlistM3") {
          this.materialType = 0;
        }
        this.getList(data);
      },
      immediate: true
    },
    materialType() {
      let data = {
        carModelName:
            this.type === "carlistX3"
                ? "THE X3"
                : "THE X3"
      };
      this.getList(data);
    },
  },
  data() {
    return {
      language: this.$i18n.locale,
      shopList: [],
      description_list: [],
      hasInnerList: false,
      completionTime: "",
      week: "",
      clientAdders: cname,
      isPresell: null,
      materialType: 1,
    };
  },
  mounted() {
    if (window.sessionStorage.getItem("isCompletion")) {
      window.sessionStorage.removeItem("isCompletion");
      this.shopList = JSON.parse(window.localStorage.getItem("activeShopList"));
    }
    window.localStorage.removeItem("activeShopList");
    window.localStorage.removeItem("shopCar");
  },
  methods: {
    formatPrice(val) {
      if (val == null || val === "null" || val == '') {
        val = "0"
      }
      let unit = this.language === 'zh' ? '￥ ' : '$ '
      return val.toString().replace(/\d+/, function (n) {
        return unit + n.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      });
    },
    toGo() {
      let hasShop = false;
      this.shopList.forEach((item) => {
        if (!this.hasInnerList) {
          item.attrList.forEach((item) => {
            if (item.active) {
              hasShop = true;
            }
          });
        } else {
          item.attrList.forEach((item) => {
            if (item.innerList) {
              item.innerList.forEach((item) => {
                if (item.active) {
                  hasShop = true;
                }
              });
            } else {
              if (item.active) {
                hasShop = true;
              }
            }
          });
        }
      });
      if (!hasShop) {
        let warn = this.$t('order.main.warning');
        this.$message.warning(warn);
        return;
      }
      window.localStorage.setItem(
          "activeShopList",
          JSON.stringify(this.shopList)
      );
      if (!localStorage.getItem("ticket")) {
        window.sessionStorage.setItem("isCompletion", JSON.stringify(this.$route.query));
        this.$router.push({name: "LoginPage"});
      } else {
        this.$router.push({
          name: "SettlementPage",
          query: {cartype: this.type, completionTime: this.completionTime}
        });
      }
    },
    hanldercheck(item, attr, idx) {
      attr.active = !attr.active;
      attr.num = attr.active ? 1 : 0;
      if (item.attrList.length > 1) {
        item.attrList.forEach((item, index) => {
          if (index !== idx) {
            item.active = false;
            item.num = 0;
          }
        });
      }
    },
    handlerCheckInner(item, attr, attrInner) {
      attrInner.active = !attrInner.active;
      attrInner.num = attrInner.active ? 1 : 0;
      if (item.attrList.length > 1) {
        item.attrList.forEach((item, index) => {
          item.innerList.forEach((innerItem, indexInner) => {
            if (attrInner.index !== innerItem.index) {
              innerItem.active = false;
              innerItem.num = 0;
            }
          })
        });
      }
    },
    getList(data) {
      this.$api
          .getCarDetailAndTranslatorsByName(data)
          .then((res) => {
            if (res.code === 0) {
              this.isPresell = res.data.isPresell;
              res.data.listAccessoriesList.sort((a, b) => {
                return a.partsIndex - b.partsIndex;
              });
              this.shopList = []
              if (this.type === "carlistX3") {
                let inner_list = []
                let order_list = res.data.listAccessoriesList.filter(item => item.accessoriesListOrderTranslators !== null).map(item => {
                  return item //.accessoriesListOrderTranslators
                })

                inner_list = res.data.listAccessoriesList.filter(item => item.accessoriesListDetailsTranslators != null).map((item) => {
                  let index = item.accessoriesListDetailsTranslators.accessoriesListIndex;
                  return {
                    index: item.accessoriesListDetailsTranslators.accessoriesListIndex,
                    img: item.accessoriesListOrderTranslators.accessoriesListPicture,
                    title: item.accessoriesListOrderTranslators.accessoriesListTitle1,
                    title_en: item.accessoriesListOrderTranslators.accessoriesListTitle1En,
                    info: item.accessoriesListOrderTranslators.accessoriesListMainBody,
                    info_en: item.accessoriesListOrderTranslators.accessoriesListMainBodyEn,
                    attrList: order_list.filter(rec => rec.accessoriesListOrderTranslators.accessoriesListIndex === index).map(me => {
                      this.description_list = [{
                        ch: me.accessoriesListOrderTranslators.accessoriesListFitDescription1,
                        en: me.accessoriesListOrderTranslators.accessoriesListFitDescription1En
                      }, {
                        ch: me.accessoriesListOrderTranslators.accessoriesListFitDescription2,
                        en: me.accessoriesListOrderTranslators.accessoriesListFitDescription2En
                      },]
                      console.log(me.accessoriesListOrderTranslators.accessoriesListRemark)
                      console.log(me.accessoriesListOrderTranslators.accessoriesListRemarkEn)
                      return {
                        id: me.accessoriesListOrderTranslators.id,
                        type: me.accessoriesListOrderTranslators.accessoriesListFitDescriptionMainBody,
                        type_en: me.accessoriesListOrderTranslators.accessoriesListFitDescriptionMainBodyEn,
                        subType: me.accessoriesListOrderTranslators.accessoriesListRemark,
                        subTypeEn: me.accessoriesListOrderTranslators.accessoriesListRemarkEn,
                        price:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? me.partsPrice : me.partsPrice)
                                : res.data.advanceDeposit,
                        price_dollar:
                            res.data.isPresell == "0"
                                ? (this.materialType === 1 ? me.partsPriceDollar : me.partsPriceDollar)
                                : res.data.advanceDeposit,
                        title: me.accessoriesListOrderTranslators.accessoriesListTitle1,
                        title_en: me.accessoriesListOrderTranslators.accessoriesListTitle2,
                        partsPrice: this.materialType === 1 ? me.partsPrice : me.partsPrice,
                        advanceDeposit: res.data.advanceDeposit,
                        num: 0,
                        active: this.shopList.length ? this.shopList[0].attrList[0].active : false,
                        x3: true,
                      }
                    }),
                    list: this.description_list,
                  }
                })
                this.shopList = inner_list
                console.log(this.shopList)
              }
              if (res.data.isPresell == "0") {
                this.completionTime = res.data.completionTime
                    ? res.data.completionTime.split(" ")[0]
                    : "";
                this.week = res.data.completionTime
                    ? this.$weekFormat(res.data.completionTime.split(" ")[0].replace(/-/g, "/"))
                    : "";
              } else if (res.data.isPresell == "1") {
                this.completionTime = res.data.releaseTime
                    ? res.data.releaseTime.split(" ")[0]
                    : "";
                this.week = res.data.releaseTime
                    ? this.$weekFormat(res.data.releaseTime.split(" ")[0].replace(/-/g, "/"))
                    : "";
              }
            }
          })
          .catch((err) => {
            console.error("getCarDetailAndTranslatorsByName", err);
          });
    }
  }
};
</script>


<style lang="scss" scoped>
@import "../../../scss/OrderPage.scss";

.empty-box {
  margin: 160px 0;
  display: flex;
  flex-direction: column;

  div:first-child {
    font-size: 100px;
    line-height: 1;
  }

  div:last-child {
    font-size: 48px;
    line-height: 1;
    margin-top: 20px;
  }
}
</style>
