var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c(
          "div",
          { staticClass: "content-box" },
          [
            _c("div", { staticClass: "banner-box" }, [
              _c("img", {
                staticClass: "welcome",
                attrs: { src: "https://www.achencybe.com/img/pc/order/1.png" },
              }),
              _c("img", {
                staticStyle: { width: "1300px", "margin-top": "40px" },
                attrs: { src: _vm.bannerImg },
              }),
            ]),
            [
              _c("div", { staticClass: "shop-box" }, [
                _c(
                  "div",
                  { staticClass: "tips-text" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("order.main.title_1")) + " "),
                    _c(
                      "el-link",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              name: "FooterDetail",
                              query: { type: "taobao" },
                            })
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("order.main.title_2")) + " ")]
                    ),
                    _vm._v(" " + _vm._s(_vm.$t("order.main.title_3")) + " "),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "shop-list" },
                  _vm._l(_vm.shopList, function (item) {
                    return _c("div", { key: item.id }, [
                      !item.hidden
                        ? _c("div", [
                            _c("div", { staticClass: "line" }),
                            _c("div", { staticClass: "shop-item" }, [
                              _c("img", { attrs: { src: item.img } }),
                              _c("div", { staticClass: "shop-info" }, [
                                _vm.$i18n.locale === "zh"
                                  ? _c("h2", [
                                      _vm._v(_vm._s(_vm.$t(item.title))),
                                    ])
                                  : _c("h2", [
                                      _vm._v(_vm._s(_vm.$t(item.title_en))),
                                    ]),
                                _vm.$i18n.locale === "zh"
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.$t(item.info))),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.$t(item.info_en))),
                                    ]),
                                _c(
                                  "ul",
                                  _vm._l(item.list, function (li) {
                                    return _c(
                                      "li",
                                      { key: li },
                                      [
                                        _vm.$i18n.locale === "zh"
                                          ? [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t(li.ch)) +
                                                  " "
                                              ),
                                            ]
                                          : [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t(li.en)) +
                                                  " "
                                              ),
                                            ],
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                ),
                                item.attrList && item.attrList.length <= 2
                                  ? _c(
                                      "div",
                                      { staticClass: "attr-box" },
                                      _vm._l(
                                        item.attrList,
                                        function (attr, idx) {
                                          return _c("div", { key: idx }, [
                                            _vm.$i18n.locale === "zh"
                                              ? _c(
                                                  "h2",
                                                  {
                                                    staticStyle: {
                                                      "white-space": "nowrap",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t(attr.type))
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "h2",
                                                  {
                                                    staticStyle: {
                                                      "white-space": "nowrap",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(attr.type_en)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                            attr.subType &&
                                            _vm.$i18n.locale === "zh"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "10px 0",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(attr.subType) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            attr.subTypeEn &&
                                            _vm.$i18n.locale !== "zh"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "10px 0",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(attr.subTypeEn) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            attr.x3 &&
                                            attr.title &&
                                            _vm.$i18n.locale === "zh"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "10px 0",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(attr.title) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            attr.x3 &&
                                            attr.title &&
                                            _vm.$i18n.locale !== "zh"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      margin: "10px 0",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(attr.title_en) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "button",
                                              {
                                                class: { active: attr.active },
                                                staticStyle: {
                                                  "font-family":
                                                    "'ELEMENT-ICONS'",
                                                  display: "flex",
                                                  "align-items": "center",
                                                  "justify-content": "center",
                                                },
                                                style: {
                                                  paddingLeft:
                                                    _vm.isPresell == "1"
                                                      ? "40px"
                                                      : "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.hanldercheck(
                                                      item,
                                                      attr,
                                                      idx
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm.isPresell === "1"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "22px",
                                                          width: "auto",
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "order.main.deposit"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c("span", {
                                                      staticStyle: {
                                                        "font-size": "22px",
                                                        width: "auto",
                                                        "font-weight": "500",
                                                      },
                                                    }),
                                                _vm.language === "zh"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "22px",
                                                          width: "auto",
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatPrice(
                                                              attr.price
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "22px",
                                                          width: "auto",
                                                          "font-weight": "500",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatPrice(
                                                              attr.price_dollar
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm.language === "zh"
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#a9a9a9" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "order.main.send.send_title"
                                              )
                                            ) +
                                            " " +
                                            _vm._s(_vm.clientAdders)
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "order.main.send.send_estimation"
                                              )
                                            ) +
                                            _vm._s(_vm.completionTime) +
                                            " (" +
                                            _vm._s(_vm.week) +
                                            ") " +
                                            _vm._s(
                                              _vm.$t(
                                                "order.main.send.send_finish"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "buy-box" }, [
                _c("div", [_vm._v(_vm._s(_vm.$t("order.main.footer")))]),
                _c("button", { on: { click: _vm.toGo } }, [
                  _vm._v(_vm._s(_vm.$t("order.main.footer_button"))),
                ]),
              ]),
              (_vm.language = this.$i18n.locale === "zh" ? "zh" : "en")
                ? _c("div", { staticStyle: { display: "none" } })
                : _vm._e(),
            ],
          ],
          2
        ),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }